









import Vue from 'vue';
import DeleteModal from '@/components/DeleteModal.vue';

export default Vue.extend({
  name: 'DeleteForm',
  components: { DeleteModal },
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    url(): string {
      return `/project/${this.projectId}/form/${this.$route.params.formId}`;
    },
  },
  methods: {
    async onDelete() {
      await this.$router.replace(`/org/${this.$route.params.orgId}/project/${this.projectId}/forms`);
    },
  },
});
