


























import Vue from 'vue';
import { DataForm } from '@/types/dataForm';
import AppCard from '@/components/card/AppCard.vue';
import ViewFormContent from '@/views/forms/ViewFormContent.vue';
import DeleteForm from '@/views/forms/DeleteForm.vue';
import FormModal from '@/views/forms/FormModal.vue';
import ApiError from '@/components/ApiError.vue';
import PageSpinner from '@/components/PageSpinner.vue';
import ExportBtn from '@/components/list/ExportBtn.vue';
import { ActiveItemState } from '@/store/activeItemModule/activeItemModule';

export default Vue.extend({
  name: 'ViewForm',
  components: {
    PageSpinner,
    ApiError,
    FormModal,
    ViewFormContent,
    DeleteForm,
    ExportBtn,
    AppCard,
  },
  computed: {
    storedForm(): ActiveItemState<DataForm> | Record<string, any> {
      const item: ActiveItemState<DataForm> = this.$store.getters['activeItemModule/details'];
      if (item.type === 'form') {
        return item;
      }
      return {};
    },
    form(): DataForm | Record<string, any> {
      if (this.storedForm) {
        return this.storedForm.details;
      }
      return {};
    },
    getUrl(): string {
      return `/project/${this.$route.params.projectId}/forms/${this.form.id}`;
    },
  },
  methods: {
    async reloadForm() {
      await this.$store.dispatch('activeItemModule/loadDetails', {
        url: this.getUrl,
        force: true,
        type: 'form',
      });
    },
  },
});
